import Vue from 'vue'; //引入Vue
import Router from 'vue-router'; //引入vue-router
import PrivacyPolicy from '../components/PrivacyPolicy'; //引入根目录下的Hello.vue组件
import ToolbarUserAgreement from '../components/ToolbarUserAgreement'
import ToolbarPrivacyPolicy from '../components/ToolbarPrivacyPolicy';
import SwordPrivacyPolicy from '../components/SwordPrivacyPolicy'
import SwordUserAgreement from '../components/SwordUserAgreement';
import HelloWorld from '../components/HelloWorld';
import UserAgreement from '../components/UserAgreement';
import MemoryUserAgreement from "../components/MemoryUserAgreement";
import MemoryPrivacyPolicy from "../components/MemoryPrivacyPolicy";
import OneUserAgreement from "../components/OneUserAgreement";
import OnePrivacyPolicy from "../components/OnePrivacyPolicy";

Vue.use(Router)  //Vue全局使用Router

export default new Router({
    routes: [              //配置路由，这里是个数组
        {                    //每一个链接都是一个对象
            path: '/UserAgreement',         //链接路径
            name: 'UserAgreement',     //路由名称，
            component: UserAgreement, //对应的组件模板
            meta:{
                // 页面标题title
                title: '用户协议'
            }
        },
        {                    //每一个链接都是一个对象
            path: '/PrivacyPolicy',         //链接路径
            name: 'PrivacyPolicy',     //路由名称，
            component: PrivacyPolicy, //对应的组件模板
            meta:{
                // 页面标题title
                title: '隐私政策'
            }
        },
        {
            path: '/SwordUserAgreement',
            name: 'SwordUserAgreement',
            component: SwordUserAgreement,
            meta:{
                title: '刀剑用户协议'
            }
        },
        {
            path: '/SwordPrivacyPolicy',
            name: 'SwordPrivacyPolicy',
            component: SwordPrivacyPolicy,
            meta:{
                title: '刀剑隐私政策'
            }
        },
        {
            path: '/MemoryUserAgreement',
            name: 'MemoryUserAgreement',
            component: MemoryUserAgreement,
            meta:{
                title: '多学亿点用户协议'
            }
        },
        {
            path: '/MemoryPrivacyPolicy',
            name: 'MemoryPrivacyPolicy',
            component: MemoryPrivacyPolicy,
            meta:{
                title: '多学亿点隐私政策'
            }
        },
        {
            path: '/OneUserAgreement',
            name: 'OneUserAgreement',
            component: OneUserAgreement,
            meta:{
                title: '一个就够用户协议'
            }
        },
        {
            path: '/OnePrivacyPolicy',
            name: 'OnePrivacyPolicy',
            component: OnePrivacyPolicy,
            meta:{
                title: '一个就够隐私政策'
            }
        },
        {                    //每一个链接都是一个对象
            path: '/ToolbarUserAgreement',         //链接路径
            name: 'ToolbarUserAgreement',     //路由名称，
            component: ToolbarUserAgreement, //对应的组件模板
            meta:{
                // 页面标题title
                title: '状态栏歌词用户协议'
            }
        },
        {                    //每一个链接都是一个对象
            path: '/ToolbarPrivacyPolicy',         //链接路径
            name: 'ToolbarPrivacyPolicy',     //路由名称，
            component: ToolbarPrivacyPolicy, //对应的组件模板
            meta:{
                // 页面标题title
                title: '状态栏歌词隐私政策'
            }
        },
        {
            path: '/',
            name: 'HelloWorld',
            component: HelloWorld,
            meta: {
                title: '全局'
            }
        }
    ]
})