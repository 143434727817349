<template>
  <v-app >

    <v-content class="pa-2">
      <div class="text-h5 pt-5 pl-5 pr-5 font-weight-bold">全局弹幕通知用户协议</div>

      <div class="text-h5 pt-5 pl-5 pr-5 font-weight-bold">前言</div>
      <div class="text-subtitle-1 pt-2 pl-5 pr-5 ">任何单位或个人在使用全局弹幕通知App服务之前（以下简称＂全局弹幕通知＂），均应仔细阅读本条款，如不同意本条款可停止使用全局弹幕通知的服务，一旦使用，即被视为对本条款全部内容的认可和接受。

      </div>
      <div class="text-h5 pt-5 pl-5 pr-5 font-weight-bold">一、使用协议的接受与修改
      </div>
      <div class="text-subtitle-1 pt-2 pl-5 pr-5">
        全局弹幕通知著作人（以下简称“我方”）同意按照本协议的规定及其不时发布的操作规则提供基于互联网以及移动网的客户端应用软件局弹幕通知，
        供客户端使用人（以下简称“用户”）在线下载、安装、注册、使用、复制本软件。本协议是用户与我方之间的协议，我方依据本协议为用户提供服务。
        <br> 本协议是用户与我方之间的协议，我方依据本协议为用户提供服务。
        <br>   1.请用户务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款、法律适用和争议解决条款。免除或者限制责任的条款将以粗体下划线标识，用户应重点阅读
        <br>  2.本协议服务条款构成用户（无论是个人或者单位）使用全局弹幕通知所提供服务之先决条件。如用户不同意本协议服务条款或其随时对其的修改，用户应不使用或主动取消全局弹幕通知提供的服务。
        用户的使用行为将被视为其对本协议服务条款及其随时修改版本的完全接受；阅读本协议的过程中，如果用户不同意本协议或其中任何条款约定，应立即停止注册程序。
        <br>  3.如果用户在本协议生效前已成为全局弹幕通知的注册用户，则用户通过访问和/或使用全局弹幕通知，即视为用户表示同意接受本协议的全部内容。
        <br>  4.我方的《全局弹幕通知隐私保护政策》以及其他相关规则均为本协议的补充协议，与本协议不可分割且具有同等法律效力。如用户不同意《全局弹幕通知隐私保护政策》，则视为不同意本协议，用户应立即停止使用全局弹幕通知提供的服务。
        <br>  5.上述条款我方可能会随时更新，我方会将更新后的用户协议、隐私政策及相关规则（统称“用户协议”）通过系统提示、信息推送和／或用户在全局弹幕通知留下的联系方式通知和提醒用户注意。
        修改后的服务条款的主要内容会在协议顶部予以展示，一旦在全局弹幕通知上公布即取代替原来的服务条款，并构成本条款整体之一部分。用户协议变更后，如用户继续使用全局弹幕通知服务，即意味着用户同意接受。
        如用户不同意变更后的用户协议，应停止使用全局弹幕通知的服务。
      </div>

      <div class="text-h5 pt-5 pl-5 pr-5 font-weight-bold">二、用户注册</div>
      <div class="text-subtitle-1 pt-2 pl-5 pr-5 ">
        1.帐号所有权归我方，用户完成申请注册手续后，获得帐号的有限使用权。
        <br> 2.帐号使用权仅属于初始申请注册人，禁止赠与、借用、租用、转让或售卖，否则由此产生的一切责任均由用户承担，同时我方保留追究上述行为人法律责任的权利。如果发现使用者并非帐号初始注册人，我方有权在未经通知的情况下回收该帐号而无需向该帐号使用人承担法律责任，由此带来的包括并不限于用户资料等清空等损失由用户自行承担。
        <br> 3.用户承担全局弹幕通知帐号与密码的保管责任，并就其帐号及密码项下之一切活动负全部责任。用户须重视全局弹幕通知帐号密码和公开邮箱的密码保护。用户同意如发现他人未经许可使用其帐号时立即通知我方。
        <br>  4.用户帐号在丢失或遗忘密码后，须遵照全局弹幕通知的申诉途径及时申诉请求找回帐号。用户应提供能增加帐号安全性的个人密码保护资料。用户可以凭初始注册资料及个人密码保护资料填写申诉单向我方申请找回帐号，
        全局弹幕通知的密码找回机制仅负责识别申诉单上所填资料与系统记录资料的正确性，而无法识别申诉人是否系真正帐号权使用人。对用户因被他人冒名申诉而致的任何损失，我方不承担任何责任，
        用户知晓全局弹幕通知帐号及密码保管责任在于用户，我方并不承诺帐号丢失或遗忘密码后用户一定能通过申诉找回帐号。
        帐户因用户主动泄露或遭受他人攻击、诈骗等行为导致的损失及后果，依据法律规定双方各自承担风险及责任。我方在法律有明确规定的情况下承担相应的责任。
        <br> 5.用户注册帐号时不得以下列内容作为帐号名称，如发现用户帐号中含有不雅文字或不恰当名称的，我方将拒绝注册，已经注册的，将保留取消其用户资格的权利。
        <br>   (1)请勿以党和国家领导人或其他社会名人的真实姓名、字号、艺名、笔名注册；
        <br>  (2)请勿以国家机构或其他机构的名称注册；
        <br>  (3)请勿注册不文明、不健康名字，或包含歧视、侮辱、猥亵类词语的帐号；
        <br>  (4)请勿注册易产生歧义、引起他人误解或其它不符合法律法规、规章制度等规定的帐号。
      </div>

      <div class="text-h5 pt-5 pl-5 pr-5 font-weight-bold">三、用户行为</div>
      <div class="text-subtitle-1 pt-2 pl-5 pr-5 ">任何单位或个人不得利用全局弹幕通知上传、读取、下载、复制、发布、传播或者转载如下内容:<br>
        1、反对宪法所确定的基本原则的；<br>
        2、危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；<br>
        3、损害国家荣誉和利益的；<br>
        4、煽动民族仇恨、民族歧视，破坏民族团结的；<br>
        5、破坏国家宗教政策，宣扬邪教和封建迷信的；<br>
        6、散布谣言，扰乱社会秩序，破坏社会稳定的；<br>
        7、散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；<br>
        8、侮辱或者诽谤他人，侵害他人合法权益的；<br>
        9、含有法律、行政法规禁止的其他内容的信息。
      </div>



      <div class="text-h5 pt-5 pl-5 pr-5 font-weight-bold">四、知识产权及相关权利</div>
      <div class="text-subtitle-1 pt-2 pl-5 pr-5">
        1.我方是全局弹幕通知的所有权及知识产权权利人，除按照法律法规规定应由相关权利人享有权利和用户自行上载的内容以外。
        <br>
        2.我方为提供服务而使用的任何软件的一切权利均属于该软件的著作权人，未经该软件的著作权人许可，用户不得对该软件进行反向工程、反向编译或反汇编，或以其他方式发现其原始编码。<br>
        3.用户上传的内容是指用户在全局弹幕通知上上载、传播的文字、图片、视频、音频或其它任何形式的内容。用户在全局弹幕通知上传或发布内容的，用户应保证其对该等内容享有合法著作权/版权或者相应授权，
        对于用户无权利且未获得授权的作品，请勿上传至全局弹幕通知。<br>
        4.除双方另有约定外，用户使用全局弹幕通知上传、发布或传输内容即代表了用户有权且同意在全世界范围内，永久性的（除非用户主动删除或书面要求全局弹幕通知下线上述视频）、免费的授权我方：<br>
        （1）对该内容的存储、使用、发布、复制、修改、改编、出版、翻译、据以创作衍生作品、传播、表演和展示等权利；<br>
        （2）将内容的全部或部分编入其他任何形式的作品、媒体、技术中的权利；<br>
        （3）对用户的上传、发布的内容进行商业开发的权利；<br>
        （4）通过有线或无线网络向用户的计算机终端、移动通讯终端（包括但不限于便携式通讯设备如手机和智能平板电脑等）、手持数字影音播放设备、电视接收设备等提供信息的下载、点播、数据传输、移动视频业务、及其相关的宣传和推广等服务的权利；<br>
        （5）以及在先告知并征求用户的明示同意的情况下，将相关上传的内容再授权给其他第三方以上述方式使用的权利。<br>
        5.我方在此郑重提请用户注意，任何经由全局弹幕通知上载、公告、发送电子邮件或任何其它方式传送的内容，无论系公开还是私下传送，均由内容提供者、上传者承担责任，
        我方不承担任何责任。当发生第三方对用户上传内容发生争议，或者因用户进行上述内容在全局弹幕通知的上载、
        传播而导致任何第三方提出索赔要求或衍生的任何损害或损失的，由用户承担全部责任。我方有权向第三方披露用户的联系方式，用户应自行与第三方协商解决。
      </div>

      <div class="text-h5 pt-5 pl-5 pr-5 font-weight-bold">五、账号使用
      </div>
      <div class="text-subtitle-1 pt-2 pl-5 pr-5 ">
        每一个注册的全局弹幕通知账号最多在2台设备上登录，如果超出2台，会采取限制登录的方式禁止继续登录。注册全局弹幕通知账号之后，请合法使用账号所拥有的权限，任何通过违法手段破解功能及影响全局弹幕通知服务的账号，全局弹幕通知有权对账号实施封禁。
      </div>


      <div class="text-h5 pt-5 pl-5 pr-5 font-weight-bold">六、法律适用和管辖
      </div>
      <div class="text-subtitle-1 pt-2 pl-5 pr-5 ">
        1.本协议的生效、履行、解释及争议的解决均适用中华人民共和国法律。
        <br>
        2.如就本协议内容或其执行发生任何争议，应尽量友好协商解决；协商不成时，则争议各方均可向北京市海淀区人民法院提起诉讼。
        <br>
        3.本协议任一条款被视为废止、无效或不可执行，该条应视为可分的且并不影响本协议其余条款的有效性及可执行性。
      </div>

      <div class="text-subtitle-1 pt-2 pl-5 pr-5 ">
        更新时间：2020年12月07日
      </div>
    </v-content>
  </v-app>
</template>

<script>
export default {
  name: "UserAgreement"
}
</script>
