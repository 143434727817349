<template>
  <v-app>

    <v-content class="pa-2">
      <div class="text-h5 pt-5 pl-5 pr-5 font-weight-bold">刀剑隐私保护政策</div>

      <div class="text-h5 pt-5 pl-5 pr-5 font-weight-bold">前言</div>
      <div class="text-subtitle-1 pt-2 pl-5 pr-5 ">欢迎使用刀剑软件！<br>刀剑深知个人信息对您的重要性，
        我们一向庄严承诺保护使用我们的产品和服务的用户（以下统称“用户”或“您”）的个人信息及隐私安全。
        您在使用刀剑时，我们可能会收集和使用您的相关个人信息。
        我们希望通过《刀剑软件隐私保护政策》向您说明我们在收集和使用您相关个人信息时对应的处理规则等相关事宜，以便更好的保障您的权益。
        <br><br>
        以下是《刀剑软件隐私保护政策》的要点说明：
        <br>
        为帮助您注册并使用刀剑产品或服务，我们可能收集与提供服务相关的个人信息，您有权拒绝或撤回授权；
        您可以访问、更正、删除您的个人信息，改变您授权同意的范围，注销您的帐号，我们为您提供了行使这些权利的途径。
        我们采取了互联网业内标准的技术措施和数据安全措施来保护您的个人信息安全。
      </div>
      <div class="text-h5 pt-5 pl-5 pr-5 font-weight-bold">一、引言</div>
      <div class="text-subtitle-1 pt-2 pl-5 pr-5 ">本政策适用于刀剑软件。<br>
        【特别提示】 请您在使用我们的各项产品和/或服务前，仔细阅读并充分理解本政策。
        一旦您使用或继续使用刀剑产品/服务，即表示您同意我们按照本政策处理您的相关信息。
        如对本政策有任何疑问，您可以通过刀剑App中的联系我们进行反馈。
        我们可能会时不时对《刀剑软件隐私保护政策》进行修订。当《刀剑软件隐私保护政策》发生变更时，
        我们会在版本更新后以推送通知、弹窗等形式向您展示变更后的内容。
      </div>
      <div class="text-h5 pt-5 pl-5 pr-5 font-weight-bold">二、刀剑处理个人信息的法律依据</div>
      <div class="text-subtitle-1 pt-2 pl-5 pr-5 ">如果您是中华人民共和国大陆地区的用户，我们将依据《中华人民共和国网络安全法》、
        《信息安全技术个人信息安全规范》（GB/T35273-2017）以及其他相关法律法规收集和使用您的个人信息，
        为您提供刀剑产品和/或服务。我们通常只会在征得您同意的情况下收集您的个人信息。在某些情况下，
        我们可能还会基于法律义务或者履行合同之必需向您收集个人信息，或者可能需要个人信息来保护您的重要利益或其他人的利益。
      </div>

      <div class="text-h5 pt-5 pl-5 pr-5 font-weight-bold">三、我们如何收集和使用您的个人信息</div>
      <div class="text-subtitle-1 pt-2 pl-5 pr-5 ">我们会遵循正当、合法、必要的原则，出于本政策所述的以下目的，
        收集和使用您在使用服务过程中主动提供或因使用刀剑产品和/或服务而产生的个人信息。
        如果我们要将您的个人信息用于本政策未载明的其它用途，
        或基于特定目的将收集而来的信息用于其他目的，我们将以合理的方式向您告知，并在使用前再次征得您的同意。
      </div>

      <div class="text-subtitle-1 pt-2 pl-5 pr-5 font-weight-bold">3.1 帮助您成为我们的在线用户</div>
      <div class="text-subtitle-1 pt-2 pl-5 pr-5 ">
        您注册并登录刀剑帐号时，需要向我们提供以下信息：帐号名称、头像（如有）和手机号码（如有则用于实名认证）。提供上述信息并同意《刀剑用户协议》和本政策后，您可以使用刀剑的全部核心业务功能。
      </div>

      <div class="text-subtitle-1 pt-2 pl-5 pr-5 font-weight-bold">3.2 向您提供产品和/或服务</div>
      <div class="text-subtitle-1 pt-2 pl-5 pr-5 ">
        为实现刀剑的核心业务功能，我们可能需要向您收集个人信息。以下将详细列出刀剑的核心业务功能及为实现该功能所需收集的个人信息，若您拒绝收集，则无法使用该服务。
        在您注册用户时以及进行普通网络请求时，我们会收集您的手机型号、系统版本、IP信息，以便于统计我们的用户分布情况。
      </div>

      <div class="text-subtitle-1 pt-2 pl-5 pr-5 font-weight-bold">3.3 我们可能从第三方间接获取您的个人信息</div>
      <div class="text-subtitle-1 pt-2 pl-5 pr-5 ">
        您使用第三方帐号（微信、QQ）登录刀剑时，可选择授权刀剑在符合相关法律法规要求的前提下读取并获得您在该第三方平台上登记、公布、记录的公开信息（包括昵称、头像），如果不再需要，您可以通过刀剑的个人资料页面，删除绑定。
      </div>

      <div class="text-subtitle-1 pt-2 pl-5 pr-5 font-weight-bold">3.4 刀剑向用户所请求隐私权限的具体用途</div>
      <div class="text-subtitle-1 pt-2 pl-5 pr-5 ">A. 读取/修改手机存储权限<br>

        用户从手机相册中选择图片进行图像处理时，需要访问您的存储权限才能获取图片；用户保存图片和保存刀剑生成的文件时，需要访问存储权限才能正常保存。
        <br>
        B. 通知使用权
        <br>
        刀剑会利用通知使用权获取通知消息从而达到信息展示的特殊效果。
        <br>
        C. 无障碍服务
        <br>
        刀剑里面的自动任务功能需要无障碍服务，但软件绝不会进行不经过您同意的操作。
        <br>
        D. 悬浮窗权限
        <br>
        刀剑软件的动画特效展示需要悬浮窗权限来支撑。
      </div>


      <div class="text-h5 pt-5 pl-5 pr-5 font-weight-bold">四、您管理个人信息的权利</div>
      <div class="text-subtitle-1 pt-2 pl-5 pr-5 ">
        我们非常重视您对个人信息的关注，并尽全力保护您对于自己个人信息访问、更正、删除以及撤回同意的权利，以使您拥有充分的能力保障您的隐私和安全。您的权利包括
      </div>
      <div class="text-subtitle-1 pt-2 pl-5 pr-5 font-weight-bold">4.1 访问和更正您的个人信息</div>
      <div class="text-subtitle-1 pt-2 pl-5 pr-5 ">A.除法律法规规定外，您有权随时访问和更正您的个人信息，具体包括: 您可通过【设置→个人信息】修改您的信息。
        B.如果您需要查阅您在使用刀剑过程中产生的其他个人信息，在合理要求下，我们会向您提供。若您无法通过上述方式访问或者更正您的个人信息的，可以通过发送邮件至180891472@qq.com联系我们。我们会在10个工作日内回复您的访问请求。
      </div>

      <div class="text-subtitle-1 pt-2 pl-5 pr-5 font-weight-bold">4.2 删除您的个人信息</div>
      <div class="text-subtitle-1 pt-2 pl-5 pr-5 ">如果您需要注销您的个人账号，可以发送邮件， 说明您的账号与昵称，我们会在5个工作日内完成注销。 </div>


      <div class="text-subtitle-1 pt-2 pl-5 pr-5 font-weight-bold">4.3 设备权限调用</div>
      <div class="text-subtitle-1 pt-2 pl-5 pr-5 ">
        我们在提供服务的过程中，可能需要您开通一些设备权限，例如通知、相册、悬浮窗、无障碍等访问权限。您也可以在设备的【设置】功能中随时选择关闭部分或者全部权限，
        从而拒绝我们收集您相应的个人信息。在不同设备中，权限显示方式及关闭方式可能有所不同，具体请参考设备及系统开发方说明或政策。
      </div>


      <div class="text-h5 pt-5 pl-5 pr-5 font-weight-bold">五、我们发出的通知
      </div>
      <div class="text-subtitle-1 pt-2 pl-5 pr-5 ">
        我们可在我们认为必需时（例如当我们暂停刀剑服务进行维护时）不时向您发出一些与服务有关的公告。
      </div>


      <div class="text-h5 pt-5 pl-5 pr-5 font-weight-bold">六、您个人信息的存储
      </div>
      <div class="text-subtitle-1 pt-2 pl-5 pr-5 ">
        我们所有获得的信息都存放于中华人民共和国境内。
      </div>

      <div class="text-h5 pt-5 pl-5 pr-5 font-weight-bold">七、如何更新与修改本政策
      </div>
      <div class="text-subtitle-1 pt-2 pl-5 pr-5 ">
        我们可能适时修改本政策的条款，该等修改构成本政策的一部分。我们会在刀剑内，发出本《隐私政策》的更新版本或以其他适当方式提醒您相关内容的更新。
      </div>

      <div class="text-h5 pt-5 pl-5 pr-5 font-weight-bold">八、如何联系我们
      </div>
      <div class="text-subtitle-1 pt-2 pl-5 pr-5 ">
        当您对本政策有任何疑问，可以发送邮件至180891472@qq.com咨询，我们将及时解决您的问题。一般情况下，我们将在5个工作日内回复。
      </div>
      <div class="text-subtitle-1 pt-2 pl-5 pr-5 ">
        更新时间：2022年03月13日
      </div>
    </v-content>
  </v-app>
</template>

<script>
export default {
  name: "SwordPrivacyPolicy"
}
</script>
