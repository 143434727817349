<template>
  <v-app>
    <v-app-bar
        app
        color="grey darken-4"
        dark
    >
      <div class="d-flex align-center">
        <v-img
            alt="Vuetify Logo"
            class="shrink mr-2"
            contain
            :src="require('../assets/launcher.png')"
            transition="scale-transition"
            width="70"
        />
        <v-toolbar-title >全局</v-toolbar-title>
      </div>
    </v-app-bar>

    <v-content >
      <v-container style="width: 100%">
        <v-row>
          <v-col cols="12">
            <h1 class="blue--text headline font-weight-bold  mb-1">
              网站维护中...
            </h1>
          </v-col>
        </v-row>
      </v-container>
    </v-content>
  </v-app>
</template>


<script>
    export default {
        name: 'HelloWorld',
        methods: {
            tab(index) {
                window.open(index, '_blank');
            }
        }
    }
</script>
