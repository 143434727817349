import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './plugins/router';

Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
  //beforeEach是router的钩子函数，在进入路由前执行
  if (to.meta.title) {
    //判断是否有标题
    console.log(to.meta.title)
    document.title = to.meta.title
  } else {
    document.title = '首页'
  }
  next()
})

new Vue({
  vuetify,
  router: router, //可以简写router
  render: h => h(App)
}).$mount('#app');
